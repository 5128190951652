import { TenantType } from '../../types';

export interface ReportMap {
  name: string;
  type: string;
  subType?: Array<TenantType>;
  historical: HistoricalReportPaths | HistoricalReportPathsMultiYear;
}

export interface HistoricalReportPaths {
  xlsx: (year: string | number) => string;
  pdf: (year: string | number) => string;
}

export interface HistoricalReportPathsMultiYear {
  xlsx: (years: string[] | number[]) => string;
  pdf: (years: string[] | number[]) => string;
}

const reports: ReportMap[] = [
  {
    name: 'Top 50 Performing North American Airports - F&B, Newstand, Specialty Retail',
    type: '50NorthAmerica',
    historical: {
      xlsx: (year: string | number) => `Top_50_Airports_${year}.xlsx`,
      pdf: (year: string | number) => `${year} Top 50 Performing North American Airports.pdf`
    }
  },
  {
    name:
      'Top 50 Performing North American Airports - F&B, Newstand, Specialty Retail, Lounges/Suites and Passenger Services',
    type: '50NorthAmericaWithLoungesSuitesPassengerServices',
    historical: {
      xlsx: (year: string | number) =>
        `Top_50_Airports_with_loungessuites_and_passenger_services_${year}.xlsx`,
      pdf: (year: string | number) =>
        `${year} Top 50 Performing North American Airports with Lounges, Suites, and Passenger Services.pdf`
    }
  },
  {
    name: 'Top Performing North American Airports - F&B, Newstand, Specialty Retail and Duty Free',
    type: '50International',
    historical: {
      xlsx: (year: string | number) => `top_performing_north_american_airports_${year}.xlsx`,
      pdf: (year: string | number) => `${year} Top Performing North American Airports.pdf`
    }
  },
  {
    name: 'Lease Expire',
    type: 'expiring',
    historical: {
      xlsx: (years: string[] | number[]) =>
        `Leases_Due_to_Expire_by_Year_End_${years.join('_')}.xlsx`,
      pdf: (years: string[] | number[]) => `Leases Due to Expire by Year-End ${years.join('_')}.pdf`
    }
  },
  {
    name: 'Ratio Report',
    type: 'ratios',
    historical: {
      xlsx: (year: string | number) => `${year}Ratios_Report.xlsx`,
      pdf: (year: string | number) => `${year} Ratios Report.pdf`
    }
  },
  {
    name: 'All Airports',
    type: 'airports',
    historical: {
      xlsx: (year: string | number) => `${year} All Airports.pdf`,
      pdf: (year: string | number) => `All_Airports_${year}.xlsx`
    }
  },
  {
    name: 'Top 50 Terminals by Food/Beverage',
    type: '50terminals',
    subType: ['FOODBEVERAGE'],
    historical: {
      xlsx: (year: string | number) => `${year}_Top_50_Terminals_by_Food_and_Beverage.xlsx`,
      pdf: (year: string | number) => `${year} Top 50 Terminals by Food and Beverage.pdf`
    }
  },
  {
    name: 'Top 50 Terminals by Specialty Retail',
    type: '50terminals',
    subType: ['SPECIALTY'],
    historical: {
      xlsx: (year: string | number) => `${year}_Top_50_Terminals_by_Specialty_Retail.xlsx`,
      pdf: (year: string | number) => `${year} Top 50 Terminals by Specialty Retail.pdf`
    }
  },
  {
    name: 'Top 50 Terminals by Newsstand/Travel Essentials',
    type: '50terminals',
    subType: ['NEWSSTAND'],
    historical: {
      xlsx: (year: string | number) => `${year} Top 50 Terminals by News & Gifts.pdf`,
      pdf: (year: string | number) => `${year}_Top_50_Terminals_by_News_&_Gifts.xlsx`
    }
  },
  {
    name: 'Top 50 Terminals by Lounges/Suites',
    type: '50terminals',
    subType: ['LOUNGESSUITES'],
    historical: {
      xlsx: (year: string | number) => `${year} Top 50 Terminals by Lounges Suites.pdf`,
      pdf: (year: string | number) => `${year}_Top_50_Terminals_by_Lounges_Suites.xlsx`
    }
  },
  {
    name: 'Top 50 Terminals by Passenger Services',
    type: '50terminals',
    subType: ['PASSENGER'],
    historical: {
      xlsx: (year: string | number) => `${year} Top 50 Terminals by Passenger Services.pdf`,
      pdf: (year: string | number) => `${year}_Top_50_Terminals_by_Passenger_Services.xlsx`
    }
  },
  {
    name: 'Top 50 Terminals by Duty Free',
    type: '50terminals',
    subType: ['DUTYFREE'],
    historical: {
      xlsx: (year: string | number) => `${year} Top 50 Terminals by Duty Free.pdf`,
      pdf: (year: string | number) => `${year} Top 50 Terminals by Duty Free.pdf`
    }
  },
  {
    name: 'Top 50 Terminals by Passenger Traffic',
    type: '50terminalsbypassengertraffic',
    historical: {
      xlsx: (year: string | number) => `${year}_Top_50_Terminals_by_Passenger_Traffic.xlsx`,
      pdf: (year: string | number) => `${year} Top 50 Terminals by Passenger Traffic.pdf`
    }
  },
  {
    name: 'Airports Passenger Traffic',
    type: 'passengertraffic',
    historical: {
      xlsx: (year: string | number) => `Airports_Passenger_Traffic_${year}.xlsx`,
      pdf: (year: string | number) => `${year} Airports Passenger Traffic.pdf`
    }
  }
];

export default reports;
