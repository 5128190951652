import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCogs,
  faDownload,
  faFilePdf,
  faList,
  faStickyNote
} from '@fortawesome/free-solid-svg-icons';

const PUB_PATH = process.env.PUBLIC_URL;

export default [
  {
    tabId: 1,
    tabTitle: (
      <div>
        <FontAwesomeIcon icon={faFilePdf} /> Airport Instructions
      </div>
    ),
    content: (
      <div>
        <a href={`${PUB_PATH}/downloads/SR2020FactBookAirportUserGuide.pdf`}>
          <FontAwesomeIcon icon={faDownload} /> Click Here
        </a>{' '}
        to Download Airport Instructions
      </div>
    )
  },
  {
    tabId: 2,
    tabTitle: (
      <div>
        <FontAwesomeIcon icon={faList} /> Airport Listings
      </div>
    ),
    content: (
      <div>
        Airport Listings: Each airport included in AXN&apos;s OFB 2023 has entered their information
        directly into this database. This year more than 90 airports submitted their concessions
        data to AXN for the purposes of this service. The majority of airports are reporting their
        information on a terminal-by-terminal basis (all information is based on 2022 data). Where
        an airport was unable to provide the information broken down by concourse or terminal, the
        data is shown in the aggregate for the airport&apos;s entire program. The book indicates
        where &quot;airportwide information only&quot; is shown.
      </div>
    )
  },
  {
    tabId: 2,
    tabTitle: (
      <div>
        <FontAwesomeIcon icon={faStickyNote} /> Please Note
      </div>
    ),
    content: (
      <div>
        The information contained in this book has been submitted to AXN directly from the
        participating airports and concessionaires, and has been approved by all participants. AXN
        is not liable for any discrepancies that may exist.
      </div>
    )
  },
  {
    tabId: 3,
    tabTitle: (
      <div>
        <FontAwesomeIcon icon={faCogs} /> Please Note
      </div>
    ),
    content: (
      <dl className="dl-horizontal">
        <dt>
          <h4>Airport Listings:</h4>
        </dt>
        <dd>
          <p>
            This year, 90 airports submitted their data to AXN for the 2023 AX Fact Book. The
            majority of airports are reporting their information on a terminal-by-terminal basis
            (all information is based on 2022 data). If an airport was unable to provide the
            information broken down by concourse or terminal, the data is shown in the aggregate for
            the airport&apos;s entire program. The book indicates where &quot;airportwide
            information only&quot; is shown.
          </p>
        </dd>
        <dt>
          <h4>Please Note:</h4>
        </dt>
        <dd>
          <p>
            The information contained in this book has been submitted to AXN directly from the
            participating airports and concessionaires, and has been approved by all participants.
            AXN has taken significant measures to ensure data integrity. AXN does not accept
            liability for any discrepancies. Any reported discrepancies will be reviewed and updated
            when appropriate on AXN&apos;s Online Fact Book, available at{' '}
            <a href="http://2023.AXNFactBook.com">2023.AXNFactBook.com</a> with a valid
            subscription.
          </p>
        </dd>
        <dt>
          <h4>Airport Configuration:</h4>
        </dt>
        <dd>
          <p>Describes the shape of the airport. For example, T-shaped, U-shaped, fingers, etc.</p>
        </dd>
        <dt>
          <h4>Advertising:</h4>
        </dt>
        <dd>
          <p>
            Gross revenue and rent to the airport, where available, are provided. This category
            includes all revenue generated from space at the airport that has been purchased by
            companies for the purpose of marketing their brand or service.
          </p>
        </dd>
        <dt>
          <h4>Average Dwell Time:</h4>
        </dt>
        <dd>
          <p>
            The average amount of time a departing passenger spends in the secure section of the
            airport.
          </p>
        </dd>
        <dt>
          <h4>Car Rental:</h4>
        </dt>
        <dd>
          <p>
            Shows the number of car rental companies at the airport, on-site and off-site; the gross
            revenue generated by the rental companies; and the total amount of rent paid to the
            airport.
          </p>
        </dd>
        <dt>
          <h4>Concessionaire Company Details:</h4>
        </dt>
        <dd>
          <p>
            All companies are categorized by type of business and are listed alphabetically. The
            categories include food and beverage operators; specialty retailers; news and gift
            operators; passenger and concessionaire services; aviation and airport consultants;
            concessions managers and private developers; architecture, interior design and
            construction companies; advertising; concessions suppliers; and retail publications and
            trade associations. For each company, there is contact information, a company
            description and a list of airport clients.
          </p>
        </dd>
        <dt>
          <h4>Concessions Sales:</h4>
        </dt>
        <dd>
          <p>
            A breakdown by terminal/concourse of the airport&apos;s concessions sales for food and
            beverage, specialty retail, and news and gift only, unless designated &quot;airportwide
            information only.&quot; In airportwide-only cases, the aggregate gross sales for food
            and beverage, specialty retail, and news and gift is shown. This section includes the
            sales per enplanement (sales/EP), rent revenue to the airport (in some cases this
            information was not provided by the airport and therefore a &quot;0&quot; will appear)
            and current square footages (aggregate amount of space allocated to these categories)
            for these categories combined in each terminal/concourse. Also included is a list of the
            dominant airlines servicing the terminal/concourse. Please note, all sales figures are
            in U.S. dollars. Canadian airports have converted revenue and sales data by using the
            average exchange rate for 2022 of CA$1.3007 = US$1 or CA$1 = USD$0.7688, according to
            the published Bank of Canada rates at{' '}
            <a href="https://www.bankofcanada.ca/">bankofcanada.ca</a>.
          </p>
        </dd>
        <dt>
          <h4>Deplaning:</h4>
        </dt>
        <dd>
          <p>
            The number of people disembarking an airplane and arriving through the airport to
            baggage claim or ground transportation.
          </p>
        </dd>
        <dt>
          <h4>Dominant Airline:</h4>
        </dt>
        <dd>
          <p>The airline that occupies the most gates in the respective terminal.</p>
        </dd>

        <dt>
          <h4>Duty Free:</h4>
        </dt>
        <dd>
          <p>Retail shops that do not apply local or national taxes and duties to products sold.</p>
        </dd>

        <dt>
          <h4>Enplaning:</h4>
        </dt>
        <dd>
          <p>
            The number of passengers boarding an airplane from the airport to a predetermined
            destination.
          </p>
        </dd>
        <dt>
          <h4>Expansion Planned:</h4>
        </dt>
        <dd>
          <p>
            A reference as to whether the airport is expanding its concessions and/or other space in
            any given terminal or zone in its facility, how much additional space is planned and
            when the expansion is expected to be completed.
          </p>
        </dd>
        <dt>
          <h4>Expires:</h4>
        </dt>
        <dd>
          <p>
            Month, date and year in which the contract with the associated concept and operator
            expires.
          </p>
        </dd>
        <dt>
          <h4>Food/Beverage:</h4>
        </dt>
        <dd>
          <p>
            Any store within an airport that utilizes more than 50 percent of its space to offer
            food and beverage products for sale to the public.
          </p>
        </dd>
        <dt>
          <h4>List of Terminals/Concourses:</h4>
        </dt>
        <dd>
          <p>A list of names for each terminal or concourse in the airport and its abbreviation.</p>
        </dd>
        <dt>
          <h4>Management Contacts:</h4>
        </dt>
        <dd>
          <p>
            Key people to be contacted for further information about the airport&apos;s concessions
            program.
          </p>
        </dd>
        <dt>
          <h4>Management Structure:</h4>
        </dt>
        <dd>
          <p>
            Describes how the airport manages its concessions program, whether by its own staff
            directly (in which it leases and oversees the program without a third-party manager or
            developer); master concessionaire (one company which operates all of the food or all of
            the retail); multiple primes (where more than one operator is contracted to operate
            several stores in the program); private developer (third-party firm that typically
            invests capital, configures space, leases and manages the program but does not operate
            its own stores); or a hybrid structure (any combination of the above).
          </p>
        </dd>

        <dt>
          <h4>Newsstand/Travel Essentials:</h4>
        </dt>
        <dd>
          <p>
            Any store within an airport that utilizes the majority of its space for the sale of news
            publications and gift items.
          </p>
        </dd>

        <dt>
          <h4>O&amp;D to Transfers:</h4>
        </dt>
        <dd>
          <p>
            This is a ratio denoting the percentage of origination and destination passengers
            relative to the percentage of passengers transferring from one flight to another within
            the airport.
          </p>
        </dd>

        <dt>
          <h4>Parking Information:</h4>
        </dt>
        <dd>
          <p>
            Shows all parking rates; airport revenue from parking; the number of operators located
            on- or off-airport; and total number of spaces.
          </p>
        </dd>

        <dt>
          <h4>Passenger Services:</h4>
        </dt>
        <dd>
          <p>
            Refers to all non-retail or non-food services provided by the airport such as spas,
            ATMs, banking, business centers, barber shops, etc.
          </p>
        </dd>

        <dt>
          <h4>Passenger Traffic:</h4>
        </dt>
        <dd>
          <p>
            Passenger numbers by terminal or concourse, broken down by enplaning vs. deplaning
            passengers, and by international vs. domestic passengers. The passenger information data
            block also shows the increase or decrease in traffic (+/- %) compared to the previous
            year. Where available, average dwell times for each terminal/concourse are included.
          </p>
        </dd>

        <dt>
          <h4>Program Details – Category Breakdowns:</h4>
        </dt>
        <dd>
          <p>
            Information broken down by revenue category. This section includes gross sales, sales
            per enplanement, rent revenue to the airport and location size for each revenue category
            in each terminal/concourse. The segment also includes revenues from passenger services,
            currency exchange, parking and car rental. These figures represent airport-wide
            information only.
          </p>
        </dd>
        <dt>
          <h4>Ratio of Business to Leisure:</h4>
        </dt>
        <dd>
          <p>
            The percentage of total enplaning passengers that are traveling on business versus the
            percentage traveling for leisure or pleasure.
          </p>
        </dd>

        <dt>
          <h4>Ratio of Pre- to Post- Security:</h4>
        </dt>
        <dd>
          <p>
            The percentage of concessions space that is located pre-security versus post-security.
          </p>
        </dd>

        <dt>
          <h4>Rent Per Enplanement:</h4>
        </dt>
        <dd>
          <p>
            The average amount of money paid to the airport in the form of rent on a per-passenger
            basis. This figure is derived by dividing the total amount of rent from a given category
            by the number of enplaning passengers.
          </p>
        </dd>

        <dt>
          <h4>Rent to Airport:</h4>
        </dt>
        <dd>
          <p>
            The amount of revenue received by the airport in the form of rent paid by
            concessionaires.
          </p>
        </dd>

        <dt>
          <h4>Sales Per Enplanement:</h4>
        </dt>
        <dd>
          <p>
            The average amount spent by each departing passenger. This figure is a standard measure
            of performance in the industry and is derived by dividing the total gross sales figure
            by the number of enplaning passengers.
          </p>
        </dd>
        <dt>
          <h4>Specialty Retail:</h4>
        </dt>
        <dd>
          <p>
            A store that utilizes a majority of its space to sell non-news, non-food retail products
            such as apparel, souvenirs, gift items, art products, jewelry, etc.
          </p>
        </dd>

        <dt>
          <h4>Sq. Ft.</h4>
        </dt>
        <dd>
          <p>Square footage, or the amount of space occupied by the associated category.</p>
        </dd>

        <dt>
          <h4>Tenant Details:</h4>
        </dt>
        <dd>
          <p>
            Offers the name of each outlet or store; the company licensed to operate the concept; a
            general description of the primary products sold in the outlet; the terminal/concourse
            in which the store is located; the number of locations occupied by the concept; the
            total square footage of location(s); and the date on which the company&apos;s lease
            expires. In some cases, a lease may have expired but the airport has extended the
            contract beyond the publication of this book.
          </p>
        </dd>

        <dt>
          <h4>Terminal Configuration:</h4>
        </dt>
        <dd>
          <p>A brief description of the layout of an individual terminal.</p>
        </dd>
        <dt>
          <h4>+/- and %</h4>
        </dt>
        <dd>
          <p>A comparison of numbers from the 2023 Fact Book information to the 2022 Fact Book.</p>
        </dd>
      </dl>
    )
  }
];
