import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import { AppState } from '../../../../../reducers';
import { AllowedWizTypes, StepDefaultProps } from '../../../types';
import { getAirport } from '../../../../../actions/airportActions';
import { AirportInstance, SpecialQuestion } from '../../../../../types';
import { RouteComponentProps } from 'react-router-dom';
import { ActiveInstance } from '../../../../../reducers/wizardReducer';
import { AuthenticatedUser } from '../../../../../actions/usersActions';
import { faRedoAlt, faSave } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import CovidFormFields from './CovidFormFields';
import {
  getSpecialQuestionFromAPI,
  updateSpecialQuestionFromAPI
} from '../../../../../api/SpecialQuestions';
import covidQuestionSchema from './yupSchema';

interface CovidStepProps extends StepDefaultProps {
  authenticatedUser: AuthenticatedUser;
  airportInstance: AirportInstance;
  covidQuestion: string;
  setFormState: (wizType: AllowedWizTypes, formName: string, formValues: any) => void;
  activeInstance: ActiveInstance;
}

// How was your business impacted by the pandemic (eg: location closings, indoor dining limited, alcohol sales restricted or TO GO added, etc.?

const CovidStep = (props: CovidStepProps & RouteComponentProps<{ contentID: string }>) => {
  const { authenticatedUser, airportInstance, activeInstance, match } = props;

  const initialQuestionValues: SpecialQuestion = {
    airportSpecialQuestionPK: {
      airportId: airportInstance.airport.airportId,
      axnInstanceId: activeInstance.id,
      questionId: 1
    },
    answer: ''
  };

  const { id } = activeInstance;
  const { contentID } = match.params;
  const { userId } = authenticatedUser;

  const [specialQuestion, setSpecialQuestion] = useState<SpecialQuestion>();

  useEffect(() => {
    getSpecialQuestionFromAPI(1, +contentID, +id).then(
      response => {
        console.log(response.data);
        setSpecialQuestion(response.data);
      },
      error => {
        if (error.message.indexOf('404') < 0) {
          const errorMsg = get(
            error.responsone,
            'data.message',
            'Failed to Retrieve Covid Question'
          );
          toast.error(errorMsg);
        }
      }
    );
  }, [contentID, id]);

  return (
    <>
      <Formik
        initialValues={{ ...initialQuestionValues, ...specialQuestion }}
        enableReinitialize
        validationSchema={covidQuestionSchema}
        onSubmit={values => {
          const specialQuestionOrDefault = { ...initialQuestionValues, ...specialQuestion };

          /* Grab the values necessary and only send them off. */
          const putValues: SpecialQuestion = {
            airportSpecialQuestionPK: specialQuestionOrDefault.airportSpecialQuestionPK,
            answer: values.answer
          };

          updateSpecialQuestionFromAPI(
            +contentID,
            id,
            specialQuestionOrDefault.airportSpecialQuestionPK.questionId ?? 1,
            putValues
          )
            .then(() => {
              toast.success('Question Saved Successfully!');
            })
            .catch(error => {
              toast.error('Question Failed to Save!');
            });
        }}
        render={({ handleBlur, handleChange, handleReset, handleSubmit, values }) => {
          return (
            <form
              className={cssClass}
              onReset={handleReset}
              onSubmit={handleSubmit}
              onChange={handleChange}>
              <StepHeader stepTitle="COVID-19 Information" />
              <Row>
                <CovidFormFields
                  values={values}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  axnInstanceId={id}
                  airportId={airportInstance.airport.airportId}
                  questionToAsk={`How was your business impacted by the pandemic (eg: location closings, indoor dining limited, alcohol sales restricted or TO GO added, etc.)?`}
                />
              </Row>
              <Row className="pt-5">
                <Col>
                  <ButtonGroup>
                    <Button color="primary" type="submit" onClick={() => handleSubmit}>
                      Save Change <FontAwesomeIcon icon={faSave} />
                    </Button>
                    <Button color="secondary" type="reset" onClick={() => handleReset}>
                      Reset <FontAwesomeIcon icon={faRedoAlt} />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </form>
          );
        }}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  airportInstance: state.airports.airportInstance,
  activeInstance: state.wizard.activeInstance,
  authenticatedUser: state.users.authenticatedUser
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAirport: getAirport(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CovidStep);
