import { get } from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Alert, Col, Container, Row } from 'reactstrap';
import { Dispatch } from 'redux';
import { getAirport } from '../../actions/airportActions';
import { initGetCompany } from '../../actions/companiesActions';
import { getTerminals } from '../../actions/terminalsActions';
import {
  AvailableInstanceMasterStatus,
  dispatchSwitchWizardInstance
} from '../../actions/wizardActions';
import { AppState } from '../../reducers';
import { AxnInstanceStatus } from '../../types';
import AirportContactsStep from './airport/steps/contacts/ContactsStep';
import CovidStep from './airport/steps/covid/CovidStep';
import ExpansionStep from './airport/steps/expansion/ExpansionStep';
import InfoStep from './airport/steps/info/InfoStep';
import LocationStep from './airport/steps/location/LocationStep';
import RentalParkingStep from './airport/steps/rental-parking/RentalParkingStep';
import ReviewStep from './airport/steps/review/ReviewStep';
import TenantsStep from './airport/steps/tenants/TenantsStep';
import TerminalsStep from './airport/steps/terminals/TerminalsStep';
import CompanyContactsStep from './company/steps/contacts/ContactsStep';
import CompanyInfoStep from './company/steps/info/InfoStep';
import CompanyLocationStep from './company/steps/location/LocationStep';
import CompanyReviewStep from './company/steps/review/ReviewStep';
/* Wizard Stepper CSS */
import { cssClass } from './cssSharedClasses';
import StepDetails from './StepDetails';
import StepHeader from './StepHeader';
import './stepper.scss';
import StepperBottomNavigator from './StepperBottomNavigator';
import StepperTopNavigator from './StepperTopNavigator';
import { AllowedWizTypes, StepConfig, StepperProps } from './types';

interface StepperUrlParams {
  contentType: AllowedWizTypes;
  contentID: string;
}

const Stepper = (props: StepperProps & RouteComponentProps<StepperUrlParams>) => {
  const {
    authenticatedUser,
    match,
    setAirport,
    setCompany,
    setTerminals,
    airportInstance,
    companyInstance,
    location,
    activeInstance,
    switchWizardInstance
  } = props;

  const { id, status, name } = activeInstance;
  const {
    axnInstanceId,
    axnPendingInstanceId,
    axnInstanceName,
    axnPendingInstanceName
  } = authenticatedUser;
  const { contentID, contentType } = match.params;

  useEffect(() => {
    let mounted = true;
    if (contentType === 'airports' && mounted) {
      setAirport(+contentID, id);
      setTerminals(+contentID, id);
    } else if (contentType === 'companies' && mounted) {
      setCompany(+contentID, id);
    }
    return () => {
      mounted = false;
    };
  }, [contentID, id]);

  useEffect(() => {
    const instanceIdToUse = status === 'PENDING' ? axnPendingInstanceId : axnInstanceId;
    const instanceYearToUse = status === 'PENDING' ? axnPendingInstanceName : axnInstanceName;
    switchWizardInstance(
      instanceIdToUse,
      status as AvailableInstanceMasterStatus,
      instanceYearToUse
    );
  }, [status, axnPendingInstanceId, axnInstanceId]);

  // remove trailing slashes so url/path can be built properly.
  const replacedUrl = match.url.replace(/\/$/, '');
  const replacedPath = match.path.replace(/\/$/, '');
  const stepsConfig: { airports: StepConfig[]; companies: StepConfig[] } = {
    airports: [
      {
        key: 0,
        exact: false,
        component: InfoStep,
        url: `${replacedUrl}/info`,
        path: `${replacedPath}/info`,
        title: 'Airport Info'
      },
      {
        key: 1,
        exact: false,
        component: ExpansionStep,
        url: `${replacedUrl}/expansion`,
        path: `${replacedPath}/expansion`,
        title: 'Planned Expansion'
      },
      {
        key: 2,
        exact: false,
        component: AirportContactsStep,
        url: `${replacedUrl}/contacts`,
        path: `${replacedPath}/contacts/:contactID?`,
        title: 'Airport Contacts'
      },
      {
        key: 3,
        exact: false,
        component: TerminalsStep,
        url: `${replacedUrl}/terminals`,
        path: `${replacedPath}/terminals`,
        title: 'Airport Terminals'
      },
      {
        key: 4,
        exact: false,
        component: RentalParkingStep,
        url: `${replacedUrl}/rental-parking`,
        path: `${replacedPath}/rental-parking`,
        title: 'Airport Rental & Parking'
      },
      {
        key: 5,
        exact: false,
        component: TenantsStep,
        url: `${replacedUrl}/tenants`,
        path: `${replacedPath}/tenants/:tenantID?/:terminalID?/:locationNumber?/:companyId?/:brandId?`,
        title: 'Airport Tenants'
      },
      {
        key: 6,
        exact: false,
        component: CovidStep,
        url: `${replacedUrl}/covid`,
        path: `${replacedPath}/covid`,
        title: 'COVID-19'
      },
      {
        key: 7,
        exact: false,
        component: LocationStep,
        url: `${replacedUrl}/location`,
        path: `${replacedPath}/location`,
        title: 'Airport Location'
      },
      {
        key: 8,
        exact: false,
        component: ReviewStep,
        url: `${replacedUrl}/review`,
        path: `${replacedPath}/review`,
        title: 'Airport Review'
      }
    ],
    companies: [
      {
        key: 0,
        exact: false,
        component: CompanyInfoStep,
        url: `${replacedUrl}/info`,
        path: `${replacedPath}/info`,
        title: 'Company Information'
      },
      {
        key: 1,
        exact: false,
        component: CompanyContactsStep,
        url: `${replacedUrl}/contacts`,
        path: `${replacedPath}/contacts/:contactID?/:companyType?`,
        title: 'Company Contacts'
      },
      {
        key: 2,
        exact: false,
        component: CompanyLocationStep,
        url: `${replacedUrl}/location`,
        path: `${replacedPath}/location`,
        title: 'Company Locations'
      },
      {
        key: 3,
        exact: false,
        component: CompanyReviewStep,
        url: `${replacedUrl}/review`,
        path: `${replacedPath}/review`,
        title: 'Company Review'
      }
    ]
  };

  const stepsToMap = stepsConfig[contentType || 'airports'];
  const currentStep = stepsToMap.filter(step => step.url === location.pathname);
  const currentStepKey = currentStep.length ? currentStep[0].key : -1;
  const nextStep = currentStepKey + 1;
  const prevStep = currentStepKey - 1;

  const company = get(companyInstance, 'company', null);
  const content = contentType === 'airports' ? airportInstance : company;

  const companyInstanceStatus = get(companyInstance, 'axnInstanceStatus', 'INPROGRESS');
  const axnInstanceStatus: AxnInstanceStatus = get(
    content,
    'axnInstanceStatus',
    companyInstanceStatus
  );

  const singularContentType = contentType === 'airports' ? 'airport' : 'company';

  /* We have to handle these different because the response apis are not similar. */
  let contentName = '';
  if (content) {
    contentName =
      contentType === 'airports'
        ? content[singularContentType][`${singularContentType}Name`]
        : content[`${singularContentType}Name`];
  }

  const getEditMessage = () => {
    switch (activeInstance.status) {
      case 'PENDING': {
        return 'Next Year (Pending)';
      }
      case 'ACTIVE': {
        return 'Current Year (Active)';
      }
      default: {
        return '';
      }
    }
  };

  const getEditMessageStyle = () => {
    if (activeInstance.status == 'ACTIVE') {
      return 'warning';
    }
    return 'info';
  };

  return (
    <Container id="wizard-stepper">
      <Row>
        <Col>
          <Switch>
            <Route
              render={() => (
                <>
                  <StepDetails
                    contentID={+contentID}
                    contentType={contentType}
                    contentName={contentName}
                    instanceID={id}
                    instanceName={name}
                    switchWizardInstance={switchWizardInstance}
                    status={status as AvailableInstanceMasterStatus}
                  />
                  <StepperTopNavigator stepsToMap={stepsToMap} />
                  <Alert color={getEditMessageStyle()}>
                    You are editing <strong>{getEditMessage()}</strong>
                  </Alert>
                </>
              )}
            />
          </Switch>
          <Switch>
            {stepsToMap.map(item => {
              const route = !content ? (
                <Route
                  key={item.key}
                  exact={item.exact}
                  path={`${item.path}`}
                  render={() => {
                    return (
                      <Container className={cssClass}>
                        <StepHeader stepTitle={'No Content Available'} className={'text-center'} />
                      </Container>
                    );
                  }}
                />
              ) : (
                <Route
                  key={item.key}
                  component={item.component}
                  exact={item.exact}
                  path={`${item.path}`}
                />
              );

              return route;
            })}
            {/* Redirect to the review step if no adequate step is provided */}
            <Redirect to={`${match.url}/review`} />
          </Switch>
        </Col>
      </Row>
      <Row>
        <Col>
          <StepperBottomNavigator
            authenticatedUser={authenticatedUser}
            stepsToMap={stepsToMap}
            prevStep={prevStep}
            nextStep={nextStep}
            currentStepKey={currentStepKey}
            currentAxnInstanceStatus={axnInstanceStatus as AxnInstanceStatus}
            setContentInstance={contentType === 'airports' ? setAirport : setCompany}
            contentInstance={contentType === 'airports' ? airportInstance : companyInstance}
            activeInstanceStatus={status}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  airportInstance: state.airports.airportInstance,
  authenticatedUser: state.users.authenticatedUser,
  companyInstance: state.companies.companyInstance,
  terminals: state.terminals.terminals,
  activeInstance: state.wizard.activeInstance
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setAirport: getAirport(dispatch),
  setCompany: initGetCompany(dispatch),
  setTerminals: getTerminals(dispatch),
  switchWizardInstance: dispatchSwitchWizardInstance(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Stepper);
