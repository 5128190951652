import { Col } from 'reactstrap';
import React from 'react';
import { AxnTitle } from '../../components/shared/title';

const Glossary = () => (
  <Col sm={12} md={4} lg={4} xl={4} className={'text-center mb-4'}>
    <AxnTitle>Glossary</AxnTitle>
    <span>
      Each airport in AXN's 2023 Fact Book has entered their information directly into this
      database. This year more than 90 airports submitted their concessions data to AXN for the
      purposes of this service.
    </span>
  </Col>
);

export default Glossary;
