import React from 'react';
import { get, find } from 'lodash';
import { Badge, Col, Table } from 'reactstrap';
import { cssClass } from '../../../cssSharedClasses';
import StepHeader from '../../../StepHeader';
import Spinner from '../../../../shared/spinner/Spinner';
import {
  TenantTotalTypes,
  TerminalAirline,
  TerminalAirlineList,
  TerminalTenantTypeTotal
} from '../../../../../types';
import { vnfMsg } from './ReviewStep';
import { TerminalsList } from '../../../../../actions/terminalsActions';
import {
  useAllTerminalTotals,
  useCalculatedCombinedTerminalTotals
} from '../../../../../api/Airports';

export const ReviewGrossSales = (props: {
  terminals: TerminalsList;
  contentID: number;
  axnInstanceId: number;
}) => {
  const { terminals, contentID, axnInstanceId } = props;
  /* Get a List of All Terminal Total by Tenant Type */
  const { terminalTotals, terminalTotalsLoading } = useAllTerminalTotals(contentID, axnInstanceId);

  const { totals, totalsLoading } = useCalculatedCombinedTerminalTotals(+contentID, axnInstanceId);

  /* Shape response it more desirable result for displaying */
  const terminalTotalCombine = {
    foodbeverage: find(totals.tenanttypetotals, { tenantType: 'FOODBEVERAGE' }),
    specialty: find(totals.tenanttypetotals, { tenantType: 'SPECIALTY' }),
    newStand: find(totals.tenanttypetotals, { tenantType: 'NEWSSTAND' }),
    dutyfree: find(totals.tenanttypetotals, { tenantType: 'DUTYFREE' }),
    loungesSuites: find(totals.tenanttypetotals, { tenantType: 'LOUNGESSUITES' }),
    passengerServices: find(totals.tenanttypetotals, { tenantType: 'PASSENGER' }),
    airport: totals.airporttotals
  };

  return (
    <Col className={cssClass}>
      <StepHeader stepTitle={'Gross Sales'} Tag={'h3'} />
      {terminalTotalsLoading ? (
        <Spinner />
      ) : (
        <div className={'mb-3'}>
          {terminals.map((terminal, key) => {
            const getTenantTypeTotals = (tenantTotalType: TenantTotalTypes) =>
              find(terminalTotals, {
                airportTerminalTotalPK: {
                  ...terminal.airportTerminalPK,
                  tenantType: tenantTotalType
                }
              }) as TerminalTenantTypeTotal;

            const tenantTypeTotals: { [key: string]: any } = {
              foodBeverage: {
                ...getTenantTypeTotals('FOODBEVERAGE'),
                description: 'Food & Beverage'
              },
              specialty: {
                ...getTenantTypeTotals('SPECIALTY'),
                description: 'Specialty Retail'
              },
              newStand: {
                ...getTenantTypeTotals('NEWSSTAND'),
                description: 'News & Gifts'
              },
              dutyfree: {
                ...getTenantTypeTotals('DUTYFREE'),
                description: 'Duty Free'
              },
              loungesSuites: {
                ...getTenantTypeTotals('LOUNGESSUITES'),
                description: 'Lounges / Suites'
              },
              passengerServices: {
                ...getTenantTypeTotals('PASSENGER'),
                description: 'Passenger Services'
              }
            };

            const buildTenantTypeTotalRow = (
              tenantTypeTotal: TerminalTenantTypeTotal,
              buildTenantTypeTotalRowKey: string
            ) => {
              const {
                areaTotal,
                grossSalesTotal,
                salesEpRatio,
                rentAirportTotal
              } = tenantTypeTotal;
              const cols: { [buildTenantTypeTotalRowKey: string]: any } = {
                grossSalesTotal,
                salesEpRatio,
                rentAirportTotal
              };

              return (
                <tr key={window.btoa(buildTenantTypeTotalRowKey)}>
                  <th style={{ minWidth: '180px' }}>{tenantTypeTotal.description}</th>
                  <td key={window.btoa(buildTenantTypeTotalRowKey)}>{areaTotal || 0}</td>
                  {Object.keys(cols).map((_key: string) => {
                    const value = cols[_key];
                    return <td key={window.btoa(_key)}>${value || 0}</td>;
                  })}
                </tr>
              );
            };

            const terminalName = get(terminal, 'terminal.terminalName', 'Undefined Name');
            const enplanementValue = get(terminal, 'enplaneTotal', 0);
            const deplanementValue = get(terminal, 'deplaneTotal', 0);
            const dominantAirlines: TerminalAirlineList = get(
              terminal,
              'airlineTerminals',
              new Array<TerminalAirline>()
            );
            return (
              <Table striped bordered responsive key={key} className={'py-3'}>
                <thead>
                  <tr>
                    <th>Terminal - {terminalName}</th>
                    <th>Current Square Foot</th>
                    <th>Gross Sales</th>
                    <th>Sales/EP</th>
                    <th>Gross Rentals</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(tenantTypeTotals).map((_key: string) =>
                    buildTenantTypeTotalRow(tenantTypeTotals[_key], _key)
                  )}
                  <tr>
                    <td colSpan={5} />
                  </tr>
                  <tr>
                    <th scope={'row'}>Enplanement</th>
                    <td colSpan={4}>{enplanementValue}</td>
                  </tr>
                  <tr>
                    <th scope={'row'}>Deplanement</th>
                    <td colSpan={4}>{deplanementValue}</td>
                  </tr>
                  <tr>
                    <th scope={'row'}>Dominant Airline</th>
                    <td colSpan={4}>
                      <div className={'d-flex justify-content-center align-items-center'}>
                        {dominantAirlines.length > 0 ? (
                          dominantAirlines.map(airline => (
                            <Badge
                              color={'primary'}
                              className={'p-2 mx-3'}
                              key={airline.airline.airlineId}>
                              {airline.airline.airlineName}
                            </Badge>
                          ))
                        ) : (
                          <span>{vnfMsg}</span>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            );
          })}
        </div>
      )}
      <StepHeader Tag="h3" stepTitle={'Terminal Totals'} />
      {totalsLoading ? (
        <Spinner />
      ) : (
        <Table striped bordered responsive className={'py-3'}>
          <thead>
            <tr>
              <th scope={'col'} style={{ minWidth: '180px' }}>
                {''}
              </th>
              <th scope={'col'}>{'Current Square Foot'}</th>
              <th scope={'col'}>{'Gross Sales'}</th>
              <th scope={'col'}>{'Sales/EP'}</th>
              <th scope={'col'}>{'Gross Rentals'}</th>
              <th scope={'col'}>{'Rent/EP'}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope={'row'}>{'Food & Beverage'}</th>
              <td>{get(terminalTotalCombine.foodbeverage, 'areaTotals', 0)}</td>
              <td>${get(terminalTotalCombine.foodbeverage, 'grossSalesTotals', 0)}</td>
              <td>${get(terminalTotalCombine.foodbeverage, 'salesEpRatios', 0).toFixed(2)}</td>
              <td>${get(terminalTotalCombine.foodbeverage, 'rentAirportTotals', 0)}</td>
              <td>${get(terminalTotalCombine.foodbeverage, 'rentEpRatios', 0).toFixed(2)}</td>
            </tr>
            <tr>
              <th scope={'row'}>{'Specialty Retail'}</th>
              <td>{get(terminalTotalCombine.specialty, 'areaTotals', 0)}</td>
              <td>${get(terminalTotalCombine.specialty, 'grossSalesTotals', 0)}</td>
              <td>${get(terminalTotalCombine.specialty, 'salesEpRatios', 0).toFixed(2)}</td>
              <td>${get(terminalTotalCombine.specialty, 'rentAirportTotals', 0)}</td>
              <td>${get(terminalTotalCombine.specialty, 'rentEpRatios', 0).toFixed(2)}</td>
            </tr>
            <tr>
              <th scope={'row'}>{'News & Gifts'}</th>
              <td>{get(terminalTotalCombine.newStand, 'areaTotals', 0)}</td>
              <td>${get(terminalTotalCombine.newStand, 'grossSalesTotals', 0)}</td>
              <td>${get(terminalTotalCombine.newStand, 'salesEpRatios', 0).toFixed(2)}</td>
              <td>${get(terminalTotalCombine.newStand, 'rentAirportTotals', 0)}</td>
              <td>${get(terminalTotalCombine.newStand, 'rentEpRatios', 0).toFixed(2)}</td>
            </tr>
            <tr>
              <th scope={'row'}>{'Duty Free'}</th>
              <td>{get(terminalTotalCombine.dutyfree, 'areaTotals', 0)}</td>
              <td>${get(terminalTotalCombine.dutyfree, 'grossSalesTotals', 0)}</td>
              <td>${get(terminalTotalCombine.dutyfree, 'salesEpRatios', 0).toFixed(2)}</td>
              <td>${get(terminalTotalCombine.dutyfree, 'rentAirportTotals', 0)}</td>
              <td>${get(terminalTotalCombine.dutyfree, 'rentEpRatios', 0).toFixed(2)}</td>
            </tr>
            <tr>
              <th scope={'row'}>{'Lounges / Suites'}</th>
              <td>{get(terminalTotalCombine.loungesSuites, 'areaTotals', 0)}</td>
              <td>${get(terminalTotalCombine.loungesSuites, 'grossSalesTotals', 0)}</td>
              <td>${get(terminalTotalCombine.loungesSuites, 'salesEpRatios', 0).toFixed(2)}</td>
              <td>${get(terminalTotalCombine.loungesSuites, 'rentAirportTotals', 0)}</td>
              <td>${get(terminalTotalCombine.loungesSuites, 'rentEpRatios', 0).toFixed(2)}</td>
            </tr>
            <tr>
              <th scope={'row'}>{'Passenger Services'}</th>
              <td>{get(terminalTotalCombine.passengerServices, 'areaTotals', 0)}</td>
              <td>${get(terminalTotalCombine.passengerServices, 'grossSalesTotals', 0)}</td>
              <td>${get(terminalTotalCombine.passengerServices, 'salesEpRatios', 0).toFixed(2)}</td>
              <td>${get(terminalTotalCombine.passengerServices, 'rentAirportTotals', 0)}</td>
              <td>${get(terminalTotalCombine.passengerServices, 'rentEpRatios', 0).toFixed(2)}</td>
            </tr>
            <tr className={'table-success'}>
              <th scope={'row'}>{'Airport Totals (excluding Duty Free):'}</th>
              <td>{get(terminalTotalCombine.airport, 'areaTotals', 0)}</td>
              <td>${get(terminalTotalCombine.airport, 'grossSales', 0)}</td>
              <td>${get(terminalTotalCombine.airport, 'salesEpRatios', 0).toFixed(2)}</td>
              <td>${get(terminalTotalCombine.airport, 'grossRentals', 0)}</td>
              <td>${get(terminalTotalCombine.airport, 'rentEpRatios', 0).toFixed(2)}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </Col>
  );
};
