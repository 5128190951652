import React, { Dispatch, SetStateAction } from 'react';
import { Input } from 'reactstrap';
import { AvailableInstanceMasterStatus, WizardActionsCreator } from '../../actions/wizardActions';
import { AuthenticatedUser } from '../../actions/usersActions';

interface AxnInstanceFilterProps {
  axnStatusFilterColSize: {
    xl: number;
    lg: number;
    md: number;
    sm: number;
    xs: number;
    [key: string]: number;
  };
  auto: boolean;
  setStatus: Dispatch<SetStateAction<AvailableInstanceMasterStatus>> | WizardActionsCreator;
  status: string;
  noPadding?: boolean;
  authenticatedUser: AuthenticatedUser;
}

// /user/{userId}/instance/{axnInstanceId}/airport/{airportId}

const AxnInstanceFilter = (props: AxnInstanceFilterProps) => {
  const { axnStatusFilterColSize, status, setStatus, noPadding, auto, authenticatedUser } = props;
  const colSizes = Object.keys(axnStatusFilterColSize).reduce(
    (acc: string, value: string) => `${acc} col-${value}-${axnStatusFilterColSize[value]}`
  );
  const paddingClasses = noPadding ? ' p-0 m-0' : ' py-3';

  const { authorities } = authenticatedUser;

  const isAdmin = authorities.some(role => role.authority === 'ROLE_ADMIN');

  //TODO: Temporary block current year while entering data
  // if (!isAdmin) {
  //   setStatus('PENDING' as AvailableInstanceMasterStatus);
  // }
  setStatus('ACTIVE' as AvailableInstanceMasterStatus);

  return (
    <div
      className={`d-flex justify-content-center${auto ? ' col-auto' : colSizes} ${paddingClasses}`}>
      <Input
        className={'d-inline-block'}
        type="select"
        name="axnStatusFilter"
        onChange={e => {
          setStatus(e.currentTarget.value as AvailableInstanceMasterStatus);
        }}
        value={status}>
        {/*
        {/* TODO: Temporary block current year while entering data <option value={'ACTIVE'}>Current Year (Active)</option> 
        // Live Site
        {<option value={'ACTIVE'} defaultValue="true">Current Year (Active)</option>}
          
        // Pending Site
        {isAdmin && <option value={'ACTIVE'}>Current Year (Active)</option>}
        <option value={'PENDING'} defaultValue="true">
            Next Year (Pending)
          </option>
        */}
        {
          <option value={'ACTIVE'} defaultValue="true">
            Current Year (Active)
          </option>
        }
      </Input>
    </div>
  );
};

AxnInstanceFilter.defaultProps = {
  noPadding: false,
  auto: false
};

export default AxnInstanceFilter;
