import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import FormField from '../../../../forms/FormField';

interface AirportCovidForm {
  values: any;
  axnInstanceId: number;
  airportId: number,
  questionToAsk: string,
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: any) => void;
}

const airportCovidForm = (props: AirportCovidForm & RouteComponentProps) => {
  const { match, values, handleBlur, handleChange, questionToAsk } = props;
  return (
    <>
      <Container>
        <Row>
          <FormField
            name={`answer`}
            label={questionToAsk}
            type="textarea"
            match={match}
            value={values.answer}
            handleChange={handleChange}
            handleBlur={handleBlur}
            col={{ lg: 12 }}
            tooltipText={
              'Up to 1000 characters'
            }
          />
        </Row>
      </Container>
    </>
  );
};

export default withRouter(airportCovidForm);
